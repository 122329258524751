<template>
<h1>Organize A Hunt Goes Here</h1>
</template>

<script>
export default {
  name: "Organize"
}
</script>

<style scoped>

</style>